<template>
    <div class="out">
        <div class="item">
            <div class="item_item item_top">数据统计 <span style="margin-left:0.12rem;color:#626262;">{{format(now_time)}}</span></div>
            <div class="item_item">
                <div class="item_list">
                    <div>老师总数</div>
                    <div class="num">{{parseInt(artist.artist) + parseInt(artist.collector)}} <span>位 ({{artist.artist}}创 {{artist.collector}}藏)</span></div>
                </div>
                <div class="item_list">
                    <div>{{$t('main.taow')}}</div>
                    <div class="num">{{good_num}} <span>件</span></div>
                </div>
            </div>
            <div class="item_item">
                <div class="item_list">
                    <div>{{$t('main.tholderT')}}</div>
                    <div class="num">{{today_collect_num}} <span>次</span></div>
                </div>
                <div class="item_list">
                    <div>{{$t('main.tnwt')}}</div>
                    <div class="num">{{today_good_num}} <span>件</span></div>
                </div>
            </div>
            
            <div class="item_item">
                <div class="item_list">
                    <div>本月总额</div>
                    <div class="num">{{NumFormat(original_month)}} <span>{{$t('main.yuan')}}</span></div>
                </div>
                <div class="item_list">
                    <div>{{$t('main.lasmt')}}</div>
                    <div class="num">{{NumFormat(original_last_month)}} <span>{{$t('main.yuan')}}</span></div>
                </div>
            </div>
        </div>
        
        <div class="item" style="margin-top:0.2rem">
            <div class="item_item item_top js_sp">
                <div>{{$t('main.hissta')}}</div>
                <div class="date_list">
                    <svg t="1666167949437" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2510" ><path d="M725.333333 170.666667h74.709334C864.853333 170.666667 917.333333 223.189333 917.333333 288.096V799.893333C917.333333 864.757333 864.832 917.333333 800.042667 917.333333H223.957333C159.146667 917.333333 106.666667 864.810667 106.666667 799.904V288.106667C106.666667 223.242667 159.168 170.666667 223.957333 170.666667H298.666667v-32a32 32 0 0 1 64 0v32h298.666666v-32a32 32 0 0 1 64 0v32z m0 64v32a32 32 0 0 1-64 0v-32H362.666667v32a32 32 0 0 1-64 0v-32h-74.709334A53.354667 53.354667 0 0 0 170.666667 288.096V799.893333A53.301333 53.301333 0 0 0 223.957333 853.333333h576.085334A53.354667 53.354667 0 0 0 853.333333 799.904V288.106667A53.301333 53.301333 0 0 0 800.042667 234.666667H725.333333z m-10.666666 224a32 32 0 0 1 0 64H309.333333a32 32 0 0 1 0-64h405.333334zM586.666667 618.666667a32 32 0 0 1 0 64H309.333333a32 32 0 0 1 0-64h277.333334z" p-id="2511"></path></svg>
                    <div @click="pickTime">{{format(early_time)}}</div>
                    <span>-</span>
                    <div @click="pickTime">{{format(last_show_time)}}</div>
                </div>
            </div>
            <div class="overflow_x" ref="overflow">
            <div class="item_item list_top" ref="title">
                <div>日期</div>
                <div>{{$t('recharge.amount')}}</div>
                <div>赠送积分</div>
                <div>新增作品数</div>
                <div>消耗积分</div>
                <div>{{$t('main.holdertr')}}</div>
                <div>{{$t('main.pirceoftran')}}</div>
                <div>求购收入金额</div>
            </div>
            
            <div class="item_item list_item bold">
                <div>{{$t('main.at')}}</div>
                <div>{{recharge_all}}</div>
                <div>{{gift_all}}</div>
                <div>{{good_all.num}}</div>
                <div>{{good_all.amount}}</div>
                <div>{{collect_all.num}}</div>
                <div>{{collect_all.amount}}</div>
                <div>{{wantBuy_all}}</div>
            </div>
            
                <div class="item_item list_item" v-for="(value , key ,index) in list.good_num" :key="key">
                    <div>{{key}}</div>
                    <div>{{list.recharge[key].amount}}</div>
                    <div>{{list.gift[key].amount}}</div>
                    <div>{{value.daily}}</div>
                    <div>{{value.amount}}</div>
                    <div>{{list.collect_num[key].daily}}</div>
                    <div>{{list.collect_num[key].amount}}</div>
                    <div>{{list.want_buy[key].amount}}</div>
                </div>
            </div>
            <div class="more" @click="loadMore" v-show="is_more">{{$t('main.seemore')}}</div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            now_time:new Date(),
            last_time:new Date(),
            early_time:new Date(),
            last_show_time:new Date(),
            load_time:'',
            salesman_date:'',
            artist:'',
            good_num:'',
            today_collect_num:'',
            today_good_num:'',
            list:{
                collect_num:{},
                good_num:{},
                recharge:{},
                gift:{},
                want_buy:{}
            },
           
            
            loading:false,
            isShow:false,
            
            original_month:'',
            original_last_month:'',
            
            collect_all:{},
            good_all:{},
            recharge_all:0,
            gift_all:0,
            wantBuy_all:0,
            
            is_more:true
        }
    },
   async mounted(){
        
       await this.axios.post('auth/getTotalStatistics').then((res)=>{
            this.artist = res.data.data.artist
            this.good_num = res.data.data.good_num
            this.today_collect_num = res.data.data.today_collect_num
            this.today_good_num = res.data.data.today_good_num
            this.original_month = res.data.data.original_month
            this.original_last_month = res.data.data.original_last_month
            this.salesman_date = res.data.data.salesman_date
            this.early_time = new Date(this.salesman_date)
        })
        
        
        this.axios.post('auth/getDateAllTotal',{start:this.format(this.salesman_date),end:this.format(this.last_show_time)}).then((res)=>{
            this.good_all = res.data.data.good_num
            this.collect_all = res.data.data.collect_num
            this.recharge_all = res.data.data.recharge
            this.gift_all = res.data.data.gift
            this.wantBuy_all = res.data.data.wantBuy
        })
        this.load_time = this.return_30()
        this.axios.post('auth/getTotalStatisticsList',{start:this.format(this.load_time),end:this.format(this.last_time)}).then((res)=>{
            this.list = res.data.data
            this.list.good_num = this.SortByKeys(this.list.good_num)
            // console.log(this.list)
        })
    },
    methods:{
        SortByKeys(obj){
            let key_list = Object.keys(obj).sort((a,b)=>{return b.localeCompare(a)})
            let new_obj = {}
            for(let i = 0 ; i < key_list.length; i++ ){
                new_obj[key_list[i]] = obj[key_list[i]]
            }
            return new_obj
        },
        format(times){
            let date = new Date(times)
            let year = date.getFullYear()
            let month = this.add0(date.getMonth() + 1)
            let day = this.add0(date.getDate())
            return  year+'-'+ month +'-'+ day
        },
        getTimeList(times){
            let date = new Date(times)
            let year = date.getFullYear()
            let month = date.getMonth() + 1
            let day = date.getDate()
            return  [year,month,day]
        },
        add0(number){
            if(number < 10){
                return '0' + number
            }else{
                return number
            }
        },
        getMore(){
            if(this.loading){
                return
            }
            this.load_time = ''
            this.is_more = true
            this.load_time = this.return_30()
            this.axios.post('auth/getDateAllTotal',{start:this.format(this.early_time),end:this.format(this.last_show_time)}).then((res)=>{
                this.good_all = res.data.data.good_num
                this.collect_all = res.data.data.collect_num
                this.recharge_all = res.data.data.recharge
                this.gift_all = res.data.data.gift
                this.wantBuy_all = res.data.data.wantBuy
            })
            
            this.axios.post('auth/getTotalStatisticsList',{start:this.format(this.load_time),end:this.format(this.last_time)}).then((res)=>{
                let new_list = res.data.data
                this.list = res.data.data
                new_list.good_num = this.SortByKeys(new_list.good_num)
                this.list.good_num = new_list.good_num
                this.loading = false
            })
        },
        return_30(){
            if(this.load_time){
                this.last_time = new Date(this.load_time).getTime() - 24*60*60*1000
            }
            let now_time = new Date(this.last_time).getTime()
            let frist_time = new Date(this.early_time).getTime()
            if(now_time - frist_time > 15*24*60*60*1000){
                return now_time - 15*24*60*60*1000
            }else{
                this.is_more = false
                return frist_time
            }
        },
        
        getAll(value , obj){
            Object.keys(obj).forEach((item)=>{
                value+=parseInt(obj[item])
            })
            return value
        },
        loadMore(){
            if(this.loading){
                return
            }
            this.load_time = this.return_30()
            this.axios.post('auth/getTotalStatisticsList',{start:this.format(this.load_time),end:this.format(this.last_time)}).then((res)=>{
                let new_list = res.data.data
                new_list.good_num = this.SortByKeys(new_list.good_num)
                this.list.good_num = {...this.list.good_num,...new_list.good_num}
                this.list.collect_num = {...this.list.collect_num,...new_list.collect_num}
                this.list.recharge = {...this.list.recharge,...new_list.recharge}
                this.list.gift = {...this.list.gift,...new_list.gift}
                this.list.want_buy = {...this.list.want_buy,...new_list.want_buy}
                this.loading = false
            })
        },
        apply(){
            weui.alert('暂未开放')
        },
        NumFormat(values) {
                let value = parseFloat(values)
				if (!value) return '0.00'
				value = value.toFixed(2)
				var intPart = Math.trunc(value) // 获取整数部分
				var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
				var floatPart = '.00' // 预定义小数部分
				var value2Array = value.split('.')
				// =2表示数据有小数位
				if (value2Array.length === 2) {
					floatPart = value2Array[1].toString() // 拿到小数部分
					if (floatPart.length === 1) {
						return intPartFormat + '.' + floatPart + '0'
					} else {
						return intPartFormat + '.' + floatPart
					}
				} else {
					return intPartFormat + floatPart
				}
      },
      pickTime(index = 0){
        if(index == 1){
            weui.datePicker({id:'last',title:'结束日期',start:new Date(this.early_time),end:new Date(),defaultValue:this.getTimeList(this.last_time), onConfirm:(value)=>{
                this.last_time = new Date(value[0].value+'-'+value[1].value+'-'+value[2].value)
                this.last_show_time = this.last_time
                this.getMore()
            }})
            return
        }
        let flag = false
        weui.datePicker({id:'eart',title:this.$t('main.startdate'),start:new Date(this.salesman_date), end:new Date(),defaultValue:this.getTimeList(this.early_time), onConfirm:(value)=>{
            this.early_time = new Date(value[0].value+'-'+value[1].value+'-'+value[2].value)
            flag = true
        },onClose:()=>{
            if(!flag){
                return
            }
            this.pickTime(1)
        }})
      }

    }
}
</script>

<style scoped>
.out{
    min-height: 100vh;
    box-sizing: border-box;
		background: url(../assets/img/index_bg.png) no-repeat center;
		background-size: cover;
        background-attachment: fixed;
    padding: 0.15rem;
}
.item{
    background: rgba(255, 255, 255, 0.6);
    border-radius: 0.06rem;
    overflow: hidden;
    width: 3.45rem;
}
.item_item{
    font-size: 0.14rem;
    box-shadow: 0 -1px 0 0 inset #e5e5e5;
    display: flex;
}
.item_top{
    box-sizing: border-box;
    padding: 0.1rem 0.15rem;
}
.item_list{
    width: 50%;
    box-sizing: border-box;
    padding: 0.18rem 0.15rem;
}
.button_list{
    box-sizing: border-box;
    padding: 0.15rem 0rem;
    display: flex;
    justify-content: space-between;
}
.button_list > div{
    width: 1.64rem;
    height: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.18rem;
    color: #fff;
    border-radius: 0.05rem;
}
.button_list > div:nth-child(1){
    background: #3e476f;
}
.button_list > div:nth-child(2){
    background: #9a2a25;
}
.overflow_x{
    overflow-x: auto;
}
.list_top{
    
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    
    padding-top: 0.17rem;
    padding-bottom: 0.1rem;
}
.list_top > div{
    
    width: 0.8625rem;
    text-align: center;
    font-size: 0.14rem;
    color: #626262;
    flex: 0 0 auto;
}
.list_item{
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    
}
.list_item > div{
    width: 0.8625rem;
    text-align: center;
    font-size: 0.13rem;
    color: #000;
    flex: 0 0 auto;
    padding: 0.1rem 0;
    box-shadow: 0 -1px 0 0 inset #e5e5e5;
}   
.more{
    text-align: center;
    font-size: 0.13rem;
    color: #000;
    padding: 0.12rem 0;
}
.password-box .agree-box-out {
		padding-left: 12%;
		margin-top: 0.075rem;
		margin-bottom: 0.075rem;
	}

	.password-box .pop-in .con .pass-input>div label {
		min-width: 12%;
	}

	.password-box .pop-in .con .err-tips {
		padding-left: 12%;
	}

	.password-box .pop-in .confirm-btn {
		margin: 0 auto;
	}
    .pop {
		position: fixed;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		top: 0;
		left: 0;
	}
    .pop-in1 {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #edeae4;
		padding: 0.15rem 0.3rem;
        box-sizing: border-box;
		color: #171717;
		font-size: 0.12rem;
		width: 93%;
	}
    .pop-in1 .title {
		font-size: 0.18rem;
		display: flex;
		align-items: center;
	}

	.pop-in1 .title span {
		margin: 0 0.1rem;
	}

	.pop-in1 .title img {
		width: 0.18rem;
	}

	.pop-in1 .con .con-tips {
		margin: 0.2rem 0;
	}

	.pop-in1 .con .pass-input>div {
		display: flex;
		align-items: center;
		margin-bottom: 0.075rem;
	}

	.pop-in1 .con .pass-input>div label {
		min-width: 0.9rem;
		text-align: right;
	}

	.pop-in1 .con .pass-input>div input {
		height: 0.33rem;
		line-height: 0.33rem;
		font-size: 0.14rem;
		border-radius: 0.05rem;
		border: none;
		outline: none;
		padding-left: 0.1rem;
		width: 70%;
		box-sizing: border-box;
	}

	.pop-in1 .con .err-tips {
		padding-left: 20%;
		display: flex;
		align-items: center;
		color: #ff180e;
	}

	.pop-in1 .con .err-tips img {
		width: 0.13rem;
		margin-right: 0.1rem;
	}

	.pop-in1 .confirm-btn {
		font-size: 0.14rem;
		height: 0.33rem;
		border-radius: 0.05rem;
		width: 100%;
		margin: 0 auto;
		width: 70%;
		text-align: center;
		color: #ffffff;
		background: #9a2a25;
		line-height: 0.33rem;
		margin-top: 0.22rem;
	}
    .num{
        font-size: 0.16rem;
    }
    .num span{
        font-size: 0.12rem;
    }
    .bold > div{
        font-weight:bold;
        font-size:0.15rem!important;
    }
    .js_sp{
        justify-content: space-between;
    }
    .date_list{
        display: flex;
        align-items: center;
        box-shadow:  0 0 0 1px #626262;
        border-radius: 0.05rem;
        padding: 0 0.08rem;
    }
    .date_list svg{
        width: 0.15rem;
        height: 0.15rem;
        margin-right: 0.04rem;
    }
    .date_list > div{
        line-height: 0.15rem;
        box-sizing: border-box;        
    }
    .date_list > span{
        width: 0.2rem;
        text-align: center;
    }
</style>